.card {
    border-radius: 15px;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 15rem;
    margin: 1em;
    padding:15px;
    min-height: 10rem;
    background-color: #F6F1E7;
    height: 100%;
    transition: transform 0.25s ease,background-color 0.25s ease;
    min-height: 150px;
  }
  .card:hover{
    background-color: #1A31A9;
    cursor: pointer;
    color: white !important;
    -webkit-transform: translate3d(0px, -8px, 0px);
    -moz-transform: translate3d(0px, -8px, 0px);
    -ms-transform: translate3d(0px, -8px, 0px);
    transform: translate3d(0px, -8px, 0px);
    box-shadow: 00px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  }

  .card:hover > .card-content > p{
    color: white !important; 
  }
  
  .card:hover > .card-content > h4 {
    color: white !important; 
  }

  .link-container {
    margin-top: auto;
  }

  .link-container {
    color: black;
    text-decoration: underline !important;
    text-underline-offset: 0.2em;
    line-height: 140%;
    letter-spacing: 0.005em;
    font-size: 1.25rem;
  }

  .link-container > a {
    color: #191919;
  }
  .card:hover > .link-container {
    color: white !important;
    text-decoration: underline;
    text-decoration-color: white
  }
  .card-title {
      font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
  }
  .card-content{
    flex-grow: 1;
  }

  .clickableZone{
    margin-top: 2rem;
    padding:  unset !important;
  }

  .focusFeature {
    background-color: black;
  }

  .focusFeature > .card-content > p{
    color: white !important; 
  }
  
  .focusFeature > .card-content > h4 {
    color: white !important; 
  }

  .focusFeature > .link-container {
    color: white !important; 
    text-decoration: underline !important;
    text-decoration-color: white !important
  }