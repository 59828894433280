.titlebox {
    min-height: 50px;
    max-height: 80px;
    color: rgb(0, 0, 0);
    display: grid;
    text-align: left;
    font-size: 2.5rem;
    line-height: 120%;
    letter-spacing: -0.02em;
    font-weight:normal;
    place-items: left;
    word-wrap: break-word;
}