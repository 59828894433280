.CopyCard{
    margin-top: unset !important;
    border-radius: 1rem;
    background-color: #191919;
}

.CopyCardImageContainer {
    display: flex !important;
    justify-content: center;
}

.CopyCardImage {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.CopyTextArea{
    background-color: #191919;
    color: white;
    font-size: large;
    text-align: left;
    border-radius: 1rem;
}

.CopyCard > .row {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.CopyCard > .ui.grid > .column:not(.row), .ui.grid > .row > .column {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

.CopyCard p {
}

.CopyCard .MuiButton-secondary {
    color: white !important;
}

.CopyCard .MuiButton-secondary:hover {
    color: #191919 !important;
}

.space{
    display: flex !important;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly !important;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.ctaButton{
    background-color: white !important;
    color: #191919 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    border-width: 0.09375rem;
    border-radius: 6px;
    border-style: solid;
    transition: background-color .4s,border-color .4s;
    font-size: 1.25rem;
    line-height: 160%;
    width: 50%;
  }

  .darken{
    filter: brightness(0.75);
  }