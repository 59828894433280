@font-face {
  font-family: aeonik;
  src: url('https://cdn.demo.okta.com/fonts/aeonik-regular.woff2') format('woff2');
}

html{
  background-color: white;
}

body {
  font-family: aeonik, sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent !important;
}

a {
  font-family: aeonik, sans-serif !important;
}