.heroContainer {
    min-height: 20em;
    border-radius: 15px;
    background-color: #3F59E4;
    color: white;
    text-align: left;
    padding: 15px;
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background-image: url("https://cdn.demo.okta.com/images/graphic-left.webp");
    background-size: cover;
}

.heroContainer > .header {
    color:white;
    font-size: 2.5rem !important;
}
.card-content{
    flex-grow: 1;
  }

.heroContainer > a {
    color:white;
    text-decoration: underline;
}

.heroContainer > p {
    font-size: larger;
}

.heroContainer > button {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    font-size: large !important;
    margin-top: 5px !important;
    font-family: aeonik, sans-serif !important;
}

.primaryAction {
    background-color: white !important;
    color: black !important;
}
.primaryAction:hover {
    background-color: black !important;
    color: white !important;
}

.secondaryAction{
    box-shadow: 0 0 0 1px white inset !important;
    color: white !important;
    background: transparent !important;
}
.secondaryAction:hover{
    color: #B6CAFF !important;
    box-shadow: 0 0 0 1px #B6CAFF inset !important;
    background: rgba(255, 255, 255, 0.05) !important;
}
.linkContainer {
    margin-top: auto;
}

.header {
    font-family: aeonik, sans-serif !important;
}