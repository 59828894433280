.page-footer {
  height: 3rem;
  width: 100%;
  padding-top: 0.28571429rem;
  padding-bottom: 0.28571429rem;
  color: rgb(255, 254, 250);
  background-color: #191919 !important;
  color: white!important;
  margin-top: 4rem;
  font-size: 0.875rem;
  font-family: 'Aeonik-Regular', 'Helvetica Neue', sans-serif;
  display: flex;
}
.footer-links a {
  color: white !important;
}

.copyright {
  text-align: right;
}

.aura{
  width: 2.5em;
}
.de{
  vertical-align: middle;
}


.buttonLink {
  background: transparent;
  border: none;
  color: white !important;
}

.buttonLink:hover {
  text-decoration: underline;
}